import { backgroundTypeProp, imageProp, videoProp } from 'utils/proptypes/modules/mediaProps';

import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import TextLink from 'components/text/TextLink';
import moduleMargins from 'config/theme/moduleMargins';

/* 
    Note: The classes .module-wrapper, .module-heading, .module-body and .module-footer 
    is neccesary for blocks that should be available inside the SpecialTwoColumnsLayout.
    These classes will make sure that the SpecialTwoColumnsLayout algins the .module-body
    regarding of .module-heading and .module-footer.

    <div className="module-wrapper">
        <div className="module-heading">
            Content display above main content
        </div>
        <div className="module-body">
            Main Content
        </div>
        <div className="module-footer">
            Content display below main content
        </div>
    </div>

    This will allow the SpecialTwoColumnsLayout to achive a layout similar to below:
     _____ 
    |_____|  _____
    |     | |     |
    |_____| |_____|
            |_____|
*/

const Media = ({ background, backgroundRatio, backgroundType, isColumn, mediaQuery, transformedLink }) => (
    <div className="module-wrapper">
        <MaxWidthWrapper
            includeContentMargins
            className="module-heading"
            display="flex"
            flexDirection="column"
            px={isColumn ? 0 : null}
        >
            {transformedLink && (
                <div>
                    <TextLink
                        mb={['12px', null, null, '24px']}
                        mt={!isColumn ? moduleMargins.sm : null}
                        to={transformedLink.to}
                    >
                        {transformedLink.label}
                    </TextLink>
                </div>
            )}
        </MaxWidthWrapper>
        <div className="module-body">
            <AspectWrapper ratio={backgroundRatio}>
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery} />
            </AspectWrapper>
        </div>
        <div className="module-footer" />
    </div>
);

Media.propTypes = {
    background: PropTypes.oneOfType([imageProp, PropTypes.string, videoProp]).isRequired,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    backgroundType: backgroundTypeProp,
    isColumn: PropTypes.bool,
    mediaQuery: PropTypes.arrayOf(PropTypes.object),
    transformedLink: PropTypes.exact({
        label: PropTypes.string,
        to: PropTypes.string,
    }),
};

export default Media;
