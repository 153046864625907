import ImageInlineBlock from 'libs/wordpress/content/cmsBlocks/ImageInlineBlock';
import React from 'react';

const ImageModule = ({ ...rest }) => (
    // Because every module should be wrapped in a section
    <section>
        <ImageInlineBlock {...rest} />
    </section>
);

export default ImageModule;
