/* eslint-disable camelcase */
import Media from 'components/contentBlocks/Media';
import PropTypes from 'prop-types';
import React from 'react';
import activeSetting from 'libs/wordpress/utils/activeSetting';
import { mediaQueries } from 'config/theme/breakpoints';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import transformLink from 'libs/wordpress/utils/transformLink';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

// Pure CMS data transformation before we send it to the "real" block!
const ImageInlineBlock = ({ content, isColumn = false }) => {
    const { background_image: backgroundObj, link, settings } = content;

    let transformedLink;
    if (activeSetting(settings, 'link') && link) {
        transformedLink = transformLink(link);
    }

    // Tranform image data
    const isAboveSm = useAboveBreakpoint('sm');
    const transformedBackground = transformFlexibleBackground(backgroundObj, 'image');

    return (
        <Media
            background={isAboveSm ? transformedBackground.desktop : transformedBackground.mobile}
            backgroundRatio={isColumn ? 3 / 4 : [3 / 4, null, 16 / 9]}
            backgroundType="image"
            isColumn={isColumn}
            mediaQuery={mediaQueries.fullWidth}
            transformedLink={transformedLink}
        />
    );
};

ImageInlineBlock.propTypes = {
    content: PropTypes.shape({
        background_image: PropTypes.exact({
            desktop_background_image: PropTypes.object,
            mobile_background_image: PropTypes.object,
        }),
        link: PropTypes.object,
        settings: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    isColumn: PropTypes.bool,
};

export default ImageInlineBlock;
